// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables";
import Monitor from "views/Dashboard/Monitor";
import Billing from "views/Dashboard/Billing";
import Profile from "views/Dashboard/Profile";


import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  VersionIcon,
  BanHistoryIcon,
  PoolIcon,
  MonitorIcon,
  ActionIcon
} from "components/Icons/Icons";
import { SettingsIcon } from "@chakra-ui/icons";
import Setting from "views/Dashboard/Setting";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/control",
  },
  {
    path: "/monitor",
    name: "Monitor",
    icon: <MonitorIcon color="inherit" />,
    component: Monitor,
    layout: "/control",
  },
  // {
  //   path: "/setting",
  //   name: "Port Setting",
  //   icon: <SettingsIcon color="inherit" />,
  //   component: Setting,
  //   layout: "/control",
  // },
  {
    path: "/action",
    name: "Action [ soon ]",
    icon: <ActionIcon color="inherit" />,
    // component: Monitor,
    layout: "/control",
  },
  {
    path: "/version",
    name: "Versions [ soon ]",
    icon: <VersionIcon color="inherit" />,
    // component: Monitor,
    layout: "/control",
  },
  {
    path: "/banhistory",
    name: "Ban History [ soon ]",
    icon: <BanHistoryIcon color="inherit" />,
    // component: Monitor,
    layout: "/control",
  },
  {
    path: "/realtimepool",
    name: "Realtime Pool [ soon ]",
    icon: <PoolIcon color="inherit" />,
    // component: Monitor,
    layout: "/control",
  },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Tables,
  //   layout: "/control",
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   icon: <CreditIcon color="inherit" />,
  //   component: Billing,
  //   layout: "/control",
  // },
  {
    name: "ACCOUNT",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/control",
      },
      // {
      //   path: "/signin",
      //   name: "Sign In",
      //   icon: <DocumentIcon color="inherit" />,
      //   component: SignIn,
      //   layout: "/auth",
      // },
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   icon: <RocketIcon color="inherit" />,
      //   secondaryNavbar: true,
      //   component: SignUp,
      //   layout: "/auth",
      // },
    ],
  },
];
export default dashRoutes;
