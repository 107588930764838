// Chakra imports
import {
    Avatar,
    Box,
    Button,
    Flex,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Spinner
} from '@chakra-ui/react'
import React, { useState } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {
    ComputerIcon
} from "components/Icons/Icons";
import axios from "axios";
import { webservice } from "service";

const Devices = ({
    title,
    device
}) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const borderProfileColor = useColorModeValue(
        "white",
        "rgba(255, 255, 255, 0.31)"
    );
    const emailColor = useColorModeValue("gray.400", "gray.300");

    const { isOpen, onOpen, onClose } = useDisclosure();

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const [inputHWID, setHWID] = useState("");

    const toast = useToast();

    const addDevice = async () => {
        console.log(inputHWID);
        const registrationToast = toast({
            position: 'top-right',
            title: <><Spinner/></>,
            description: "Adding device...",
            status: 'info',
            duration: 60000,
            isClosable: true,
        })
        axios.post(`/api/addhwid`, {
            hwid: inputHWID
        }, {
            headers: {
                'x-user-id': webservice.userData.id
            },
        }).then((res) => {
            console.log(res)
            toast.close(registrationToast);
            toast({
                position: 'top-right',
                title: 'Device added.',
                description: "เพิ่ม hardware id สำเร็จ",
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            onClose()
        }).catch((err) => {
            console.log(err)
            toast.close(registrationToast);
            toast({
                position: 'top-right',
                title: 'Failed add device',
                description: "เพิ่ม hardware id ไม่สำเร็จ",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            onClose();
        });
    }


    return (
        <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
            <CardHeader p='6px 0px 22px 0px'>
                <Flex justify='space-between' align='center' mb='1rem' w='100%'>
                    <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
                        {title}
                    </Text>
                    <Button
                        colorScheme='teal'
                        borderColor='teal.300'
                        color='teal.300'
                        variant='outline'
                        fontSize='xs'
                        p='8px 32px'
                        onClick={onOpen}
                        disabled={webservice.userData ? false : true}
                    >
                        Add Device
                    </Button>
                </Flex>
            </CardHeader>
            <CardBody>
                <Modal
                    initialFocusRef={initialRef}
                    finalFocusRef={finalRef}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add new device</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Hardware ID</FormLabel>
                                <Input onChange={(e) => setHWID(e.target.value)} placeholder='HWID view on client' />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={addDevice}>
                                Save
                            </Button>
                            <Button onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {device.map((dv, idx) => {
                    return (
                        <Flex
                            direction={{ sm: "column", md: "row" }}
                            mx='1.5rem'
                            w={{ xs: "100%" }}
                            justifyContent={{ sm: "center", md: "space-between" }}
                            align='center'
                            boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
                            border='2px solid'
                            p='24px'
                            borderRadius='20px'
                        >
                            <Flex
                                align='center'
                                mb={{ sm: "10px", md: "0px" }}
                                direction={{ sm: "column", md: "row" }}
                                textAlign={{ sm: "center", md: "start" }}>
                                <ComputerIcon color="inherit" w="100px" h="120px" />
                                <Flex direction='column' maxWidth='100%' my={{ sm: "14px" }}>
                                    <Text
                                        fontSize={{ sm: "lg", lg: "xl" }}
                                        color={textColor}
                                        fontWeight='bold'
                                        ms={{ sm: "8px", md: "0px" }}>
                                        {dv.id.substr(0, 16)}
                                    </Text>
                                    {dv.info.map((dvinfo, idx) => {
                                        return (
                                            <Flex
                                                align='center'
                                                w="100%"
                                                justifyContent='center'
                                                py='10px'
                                                cursor='pointer'>
                                                {dvinfo.icon}
                                                <Text
                                                    fontSize='xs'
                                                    color={textColor}
                                                    fontWeight='bold'
                                                    ms='10px'>
                                                    {dvinfo.name}
                                                </Text>
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            </Flex>
                        </Flex>
                    );
                })}

            </CardBody>
        </Card>
    );
};

export default Devices;
