// Chakra imports
import {
  Flex,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  Switch,
  FormControl,
  FormLabel,
  Input,
  Stack,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Button 
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Ports from "./components/Port";

import { useLocation } from "react-router-dom";

import axios from "axios";
import { webservice } from "service";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

function Monitor() {
  const [portData, setPortData] = useState(false);
  const [listStatics, setListstatics] = useState([]);

  useEffect(
    () => {
      if (portData) {
        if (portData.data['statics']) {
          const ports = [];
          for (let ps of Object.keys(portData.data['statics'])) {
            const portdata = portData.data.statics[ps];
            ports.push({
              name: ps,
              unclaim: `${parseFloat(portdata.c_tlm).toFixed(4)} TLM`,
              token: `${parseFloat(portdata.t_tlm).toFixed(4)} TLM`,
              wax: `${parseFloat(portdata.b_wax).toFixed(4)} WAX`,
              totalid: portdata.ids
            });
          }
          setListstatics(ports);
        }
      }
    },
    [portData]
  );

  useEffect(
    () => {
      if (webservice.userData) {
        axios.get(`/api/monitor`, {
          headers: {
            'x-user-id': webservice.userData.id
          }
        }).then((res) => {
          setPortData(res.data);
        }).catch((err) => console.log(err));
      }
    },
    []
  );

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Skeleton height="300px" borderRadius="md" isLoaded={portData}>
        <Ports
          title={"Ports List"}
          captions={["name", "Total Id", "Total Unclaim TLM", "Total Token TLM", "TOTAL WAX", "Tool"]}
          data={listStatics}
        />
      </Skeleton>
    </Flex >
  );
}

export default Monitor;