// Chakra imports
import {
  Flex,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  Switch,
  FormControl,
  FormLabel,
  Input,
  Stack,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Button,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useToast,
  Spinner,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  Icon
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Ports from "./components/Port";

import { useLocation } from "react-router-dom";

import axios from "axios";
import { webservice } from "service";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { SaveIcon } from "components/Icons/Icons";

function Setting() {
  const textColor = useColorModeValue("gray.700", "white");

  const [configData, setConfigData] = useState([]);

  useEffect(
    () => {
      if (configData) {
        // if (portData.data['statics']) {
        //   const ports = [];
        //   for (let ps of Object.keys(portData.data['statics'])) {
        //     const portdata = portData.data.statics[ps];
        //     ports.push({
        //       name: ps,
        //       unclaim: `${parseFloat(portdata.c_tlm).toFixed(4)} TLM`,
        //       token: `${parseFloat(portdata.t_tlm).toFixed(4)} TLM`,
        //       wax: `${parseFloat(portdata.b_wax).toFixed(4)} WAX`,
        //       totalid: portdata.ids
        //     });
        //   }
        //   setListstatics(ports);
        // }
      }

    },
    [configData]
  );

  useEffect(
    () => {
      if (webservice.userData) {
        axios.get(`/api/config`, {
          headers: {
            'x-user-id': webservice.userData.id
          }
        }).then((res) => {
          setConfigData(res.data.results);
        }).catch((err) => console.log(err));
      }
    },
    []
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [inputPortame, setPortname] = useState("");

  const toast = useToast();

  const addDevice = async () => {
    const registrationToast = toast({
      position: 'top-right',
      title: <><Spinner /></>,
      description: "Adding port...",
      status: 'info',
      duration: 60000,
      isClosable: true,
    })
    axios.post(`/api/config`, {
      port: inputPortame
    }, {
      headers: {
        'x-user-id': webservice.userData.id
      },
    }).then((res) => {
      console.log(res)
      toast.close(registrationToast);
      toast({
        position: 'top-right',
        title: 'Device added.',
        description: "เพิ่ม port สำเร็จ",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose()
    }).catch((err) => {
      console.log(err)
      toast.close(registrationToast);
      toast({
        position: 'top-right',
        title: 'Failed add device',
        description: "เพิ่ม port ไม่สำเร็จ",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    });
  };

  const updateConfig = async (port) => {

  };

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Port</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Port Name</FormLabel>
              <Input onChange={(e) => setPortname(e.target.value)} placeholder='port name' />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={addDevice}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Skeleton height="300px" borderRadius="md" isLoaded={configData}>
        <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
          <CardHeader p='6px 0px 22px 0px'>
            <Flex justify='space-between' align='center' mb='1rem' w='100%'>
              <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
                Port Config
              </Text>
              <Button
                colorScheme='teal'
                borderColor='teal.300'
                color='teal.300'
                variant='outline'
                fontSize='xs'
                p='8px 32px'
                onClick={onOpen}
                disabled={webservice.userData ? false : true}
              >
                Add New Port
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant='simple' color={textColor}>
              <Thead>
                <Tr my='.8rem' pl='0px'>
                  <Th color='gray.400' ps="0px">
                    Port Name
                  </Th>
                  <Th color='gray.400'>
                    Contract
                  </Th>
                  <Th color='gray.400'>
                    Rare
                  </Th>
                  <Th color='gray.400'>
                    Epic
                  </Th>
                  <Th color='gray.400'>
                    Legendary
                  </Th>
                  <Th color='gray.400'>
                    Mythical
                  </Th>
                  <Th color='gray.400'>
                    ตรวจจับการแบน
                  </Th>
                  <Th color='gray.400'>
                    TLM ที่สั่งเคลม
                  </Th>
                  <Th color='gray.400'>
                    จำนวนการแบน
                  </Th>
                  <Th color='gray.400'>
                    บันทึก
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {configData.map((dv, idx) => {
                  return (
                    <Tr>
                      <Td minWidth={{ sm: "100px", lg: "100px" }} pl="0px">
                        <Text
                          fontSize='xs'
                          color={textColor}
                          fontWeight='bold'
                          ms='10px'>
                          {dv.portName}
                        </Text>
                      </Td>
                      <Td>
                        <Switch id={`contract-check-${dv.portName}`} checked={dv.contract} />
                      </Td>
                      <Td>
                        <Input htmlSize={4} width='auto' defaultValue={dv.contract_rare} />
                      </Td>
                      <Td>
                        <Input htmlSize={4} width='auto' defaultValue={dv.contract_epic} />
                      </Td>
                      <Td>
                        <Input htmlSize={4} width='auto' defaultValue={dv.contract_legendary} />
                      </Td>
                      <Td>
                        <Input htmlSize={4} width='auto' defaultValue={dv.contract_mythical} />
                      </Td>
                      <Td>
                        <Switch id={`ban-check-${dv.portName}`} checked={dv.claimBanTrigger} />
                      </Td>
                      <Td>
                        <Input htmlSize={4} width='auto' defaultValue={dv.claimAmount} />
                      </Td>
                      <Td>
                        <Input htmlSize={4} width='auto' defaultValue={dv.banAmount} />
                      </Td>
                      <Td>
                        <Button p="0px" bg="transparent">
                          <Icon as={SaveIcon} color="gray.400" cursor="pointer" />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Skeleton>
    </Flex >
  );
}

export default Setting;