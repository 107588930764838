/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import AdminLayout from "layouts/Admin.js";

import { webservice } from "service";

webservice.init();

ReactDOM.render(
  <GoogleOAuthProvider clientId='460213779662-dk0n6lf0aso4igaoenknvks51cughd92.apps.googleusercontent.com'>
    <HashRouter>
      <Switch>
        <Route path={`/control`} component={AdminLayout} />
        <Redirect from={`/`} to="/control/dashboard" />
      </Switch>
    </HashRouter>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
