import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Image,
  Link
} from "@chakra-ui/react";
import anonymousLogo from "assets/img/anonymous-logo.png"
import { ViewIcon, SettingsIcon } from "@chakra-ui/icons";
import { webservice } from "service";

function TablePort(props) {
  const { name, totalid, unclaim, token, wax } = props;
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Tr>
      <Td minWidth={{ sm: "100px", lg: "100px" }} pl="0px">
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Image src={anonymousLogo} h={"24px"} w={"24px"} me="18px" />
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {totalid}
        </Text>
      </Td>
      <Td>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          pb=".2rem"
        >{unclaim}</Text>
      </Td>
      <Td>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          pb=".2rem"
        >{token}</Text>
      </Td>
      <Td>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          pb=".2rem"
        >{wax}</Text>
      </Td>
      <Td>
        <Flex
          direction="row"
          w={{ sm: "100%", md: "50%", lg: "auto" }}
          >
          <Link
            href={`https://monitor.anonymous-aw.com/?googleid=${webservice.userData.id}&port=${name}`}
            isExternal
            rel="noopener noreferrer"
          >
            <Button p="0px" bg="transparent">
              <Icon as={ViewIcon} color="gray.400" cursor="pointer" />
            </Button>
          </Link>
        </Flex>
      </Td>
    </Tr>
  );
}

export default TablePort;
