import axios from 'axios';


export class WebService {

  userData = false;

  setUser = (data) => {
    this.userData = data;
    localStorage.setItem('user', JSON.stringify(data));
  };

  init = () => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      this.userData = JSON.parse(savedUser);
    }
  }
}

export const webservice = new WebService();